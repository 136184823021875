// Greek
export default{
    form: {
        startSurveyButton: "Ας ξεκινήσουμε",
        nextButton: "Επόμενο",
        submitAnswerButton: "Υποβολή απάντησης",
        continueButton: "Συνέχεια",
        finishButton: "Ολοκλήρωση",
        headerPercentageCompletion: 'ολοκληρώθηκε',
        headerQuestionsCompleted: 'ερωτήσεις',
        headerDefaultErrorMessage: 'Ωχ. Κάτι πήγε στραβά.',
        brandingPoweredBy: 'Powered by',
        brandingPromoWhySurvey: 'Γιατί έρευνα όταν μπορείς να χρησιμοποιήσεις <b>{product}</b>;',
        brandingPromoGetStarted: 'Ξεκινήστε σήμερα!',
        submissionPending: "Υποβάλλεται το voiceform σας. Μην κλείσετε το παράθυρο του προγράμματος περιήγησης.",
        submissionCanCloseWindow: "Το voiceform σας υποβλήθηκε επιτυχώς. Μπορείτε να κλείσετε το παράθυρο τώρα 👍",
        mediaAttachmentOverlayCloseButton: "Κλείσιμο",
        expiredFormHeader: "Ευχαριστούμε για την επίσκεψη!",
        expiredFormMessage: "Φαίνεται ότι αυτή η έρευνα έχει λήξει. Επικοινωνήστε με τον δημιουργό του voiceform.",
        notPublishedFormHeader: "Το voiceform δεν έχει δημοσιευτεί!",
        notPublishedFormMessage: "Φαίνεται ότι αυτή η έρευνα δεν έχει δημοσιευτεί. Επικοινωνήστε με τον δημιουργό του voiceform.",
        notPublishedFormRetryButton: "Δοκιμάστε ξανά",
        errorFormHeader: "Ωχ!",
        errorFormMessage: "Κάτι μπορεί να πήγε στραβά. Δοκιμάστε ξανά αργότερα.",
        errorFormRetryButton: "Δοκιμάστε ξανά",
        emptyFormHeader: "Ευχαριστούμε για την επίσκεψη!",
        emptyFormMessage: "Ωχ! Αυτό το έντυπο είναι κενό. Επικοινωνήστε με τον δημιουργό του για να επιλύσει το πρόβλημα. Ευχαριστούμε για την κατανόηση!",

        restoreFormHeader: "Φαίνεται ότι έχετε μια φόρμα σε εξέλιξη!",
        restoreFormMessage: "Θέλετε να συνεχίσετε από εκεί που σταματήσατε;",
        restoreFormButton: "Συνέχεια φόρμας",
        restoreFormButtonNew: "Ξεκινήστε από την αρχή",

        audioPermissionHeader: "Θέλετε να απαντήσετε με φωνή;",
        audioPermissionMessage: "Η ομιλία θα κάνει την απάντηση γρήγορη και εύκολη.",
        audioPermissionAcceptButton: "Ναι, θέλω",
        audioPermissionDenyButton: "Όχι, ευχαριστώ",
        audioPermissionErrorHeader: "Το μικρόφωνο δεν είναι διαθέσιμο",
        audioPermissionErrorMessage: "Ελέγξτε τις άδειες του προγράμματος περιήγησης για πρόσβαση στο μικρόφωνο.",
        audioPermissionErrorButton: "Εντάξει",
        audioRecorderFailedUpload: "Αποτυχία ανεβάσματος ηχογράφησης",
        silenceDetectorError: "Δεν σας ακούμε. Ελέγξτε τις ρυθμίσεις μικροφώνου.",
        audioRecorderButtonRecord: "Έναρξη εγγραφής",
        audioRecorderButtonRecordMore: "Ηχογραφήστε περισσότερο",
        voiceResponseSelectionMessage: "Επιλέξτε πώς θέλετε να απαντήσετε...",
        voiceResponseSelectionOrDivider: "ή",
        voiceResponseTextInputPlaceholder: "Πληκτρολογήστε την απάντηση",
        voiceResponseTextInputCharacterCounterMinMessage: "Εισαγάγετε τουλάχιστον {min} χαρακτήρες",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} χαρακτήρες (ελάχιστο {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} χαρακτήρες (ελάχιστο {min})",

        // checkbox
        checkboxValidationTooFew: "Πρέπει να επιλέξετε τουλάχιστον {min} επιλογή/ές",
        checkboxValidationTooMany: "Μπορείτε να επιλέξετε το πολύ {max} επιλογή/ές",
        checkboxNoneOfTheAboveOption: "Καμία από τις παραπάνω",
        checkboxInvalidAnswer: "Μη έγκυρη απάντηση.",

        // datepicker
        datePickerPlaceholder: "Επιλέξτε ημερομηνία",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid: "Μη έγκυρο email.",
        // file-upload
        fileUploadPluginNameCamera: "Κάμερα",
        fileUploadPluginNameCameraVideo: "Καταγραφή βίντεο",
        fileUploadPluginScreenCast: "Εγγραφή οθόνης",
        fileUploadVideoImportFilesDefault: 'Καταγράψτε ή ανεβάστε ένα βίντεο μέσω:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Επιλέξτε μια επιλογή καταγραφής βίντεο:',
        fileUploadVideoImportFilesNoCamera: 'Πατήστε ένα κουμπί για καταγραφή οθόνης',
        fileUploadVideoImportFilesNoScreenCast: 'Πατήστε ένα κουμπί για καταγραφή βίντεο',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Δεν υπάρχουν διαθέσιμες επιλογές για καταγραφή βίντεο',

        // matrix
        matrixValidationMessage: "Η απάντηση είναι έγκυρη.",
        matrixRow: "Σειρά",
        matrixColumn: "Στήλη",
        matrixRowRequired: "Η σειρά {rowTitle} είναι υποχρεωτική.",
        matrixRadioGroup: "Ομάδα ραδιοφώνου",
        matrixCheckboxGroup: "Ομάδα checkbox",
        matrixGroupRequired: "{type} {groupTitle} είναι απαραίτητο για τη σειρά {rowTitle}.",
        matrixColumnRequired: "Η στήλη {columnTitle} είναι υποχρεωτική για τη σειρά {rowTitle}.",
        matrixColumnInvalid: "Η στήλη {columnTitle} δεν είναι έγκυρη για τη σειρά {rowTitle}.",
        matrixRequired: "Υποχρεωτικό.",
        matrixNumericMustBeNumber: "Πρέπει να είναι αριθμός.",
        matrixNumericMustBeGreaterThenMin: "Πρέπει να είναι μεγαλύτερο από {min}.",
        matrixNumericMustBeLessThenMax: "Πρέπει να είναι μικρότερο από {max}.",
        matrixNumericMustBeInteger: "Πρέπει να είναι ακέραιος αριθμός.",
        matrixNumericInvalidNumber: "Μη έγκυρος αριθμός.",

        // name
        nameLabel: "Όνομα",
        nameInvalid: "Καθορίστε όνομα και επώνυμο.",

        // nps
        npsNotLikely: "Καθόλου πιθανό",
        npsExtremelyLikely: "Πολύ πιθανό",

        // numeric input
        numericInputRequired: "Απαιτείται.",
        numericInputMustBeNumber: "Πρέπει να είναι αριθμός.",
        numericInputMustBeGreaterThenMin: "Πρέπει να είναι μεγαλύτερο από {min}.",
        numericInputMustBeLessThenMax: "Πρέπει να είναι μικρότερο από {max}.",
        numericInputMustBeInteger: "Πρέπει να είναι ακέραιος αριθμός.",
        numericInputInvalidNumber: "Μη έγκυρος αριθμός.",
        numericInputPlaceholder: "Εισάγετε αριθμό",

        // phone
        phoneInvalid: "Ο αριθμός τηλεφώνου δεν είναι έγκυρος.",
        phoneCountrySelectorLabel: 'Κωδικός χώρας',
        phoneCountrySelectorError: 'Επιλέξτε χώρα',
        phoneNumberLabel: 'Τηλεφωνικός αριθμός',
        phoneExample: 'Παράδειγμα:',

        // boolean
        booleanYesLabel: "Ναι",
        booleanNoLabel: "Όχι",

        //questionStep
        questionStepAudioQuestionLabel: "Ηχητική ερώτηση",

        // errors
        invalidPhoneNumber: "{phone} δεν είναι έγκυρος αριθμός τηλεφώνου.",
        invalidEmail: "{email} δεν είναι έγκυρη διεύθυνση email.",
        questionIsRequired: "Η ερώτηση είναι υποχρεωτική.",
        answerIsNotValid: "Η απάντηση δεν είναι έγκυρη.",
        unfinishedProbingDialogError: "Ολοκληρώστε τη συνομιλία.",
        cannotResumePartialResponse: "Δεν είναι δυνατή η συνέχιση της μερικής απάντησης.",
        failedToSubmitForm: "Η αποστολή της φόρμας απέτυχε. Ελέγξτε τη σύνδεση στο διαδίκτυο και δοκιμάστε ξανά."
    }
}
